import React, { useCallback } from "react";
import GoogleLogin from "react-google-login";
import styles from "./Login.module.scss";
import { REDIRECT_URI, GOOGLE_CLIENT_ID } from "../../Api/Api";

const GoogleBtn: React.FC<{ checkUser: (email: string) => void }> = ({ checkUser }) => {

  const onLoginSuccess = useCallback(async (response: any) => {
    console.log("google login success", response.profileObj.email);
    checkUser(response.profileObj.email);
  }, []);

  const onLoginFailure = (e: any) => console.log("Error during logging", e);

  return (
    <div>
      <GoogleLogin clientId={GOOGLE_CLIENT_ID}
                   render={renderProps => (
                     <button onClick={renderProps.onClick}
                             className={styles.googleBtn}
                             disabled={renderProps.disabled}>Sign in with Google</button>)}
                   onSuccess={onLoginSuccess}
                   onFailure={onLoginFailure}
                   cookiePolicy='single_host_origin'
                   redirectUri={REDIRECT_URI} />}
    </div>
  )
};

export default GoogleBtn;
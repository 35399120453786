import React from "react";
import styles from "./GameTile.module.scss";

type GameTileProps = {
  image: string,
  attachParrot?: boolean,
  isAvailable?: boolean,
  message?: string;
  isSolved?: boolean
}

const GameTile: React.FC<GameTileProps> = ({ image, attachParrot = false, isAvailable = false, message, isSolved = false }) => {
  const drawParrot = () => {
    return <div style={{ zIndex: 1, position: 'absolute', top: '30%', left: '30%' }}>
      <img src={require(`../../assets/moving-parrot.gif`)} style={{ height: '7vw', width: 'auto' }}></img>
    </div>
  };
  const drawSolvedIndicator = () => {
    return <div style={{ zIndex: 1, position: 'absolute', top: '80%', left: '80%' }}>
      <img src={require(`../../assets/tick.png`)} style={{ height: '3vw', width: 'auto' }}></img>
    </div>
  };
  return (
    <div style={{ position: 'relative' }}>
      <div className={styles.tile}>
        <img src={require(`../../assets/${image}`)}></img>
      </div>
      {!isAvailable && <div style={{ position: "absolute", color: "white", fontSize: '0.7vw', top: 10, left: 10 }}>
        {message}
      </div>}
      {attachParrot ? drawParrot() : <></>}
      {isSolved ? drawSolvedIndicator() : null}
    </div>
  )
};

export default GameTile;
import React, { useCallback, useContext, useEffect, useState } from "react";
import { H2, H4 } from "../../common/Typography";
import { Form, Formik, useField } from "formik";
import theme from "../../theme.module.scss";
import styles from "./TaskView.module.scss";
import * as Yup from "yup";
import { RouteComponentProps } from "react-router";
import { RouterTask } from "../../Api/types";
import { GameContext } from "../../Contexts/GameContext";
import { getTaskData, submitTask } from "../../Api/Api";
import ParrotLoader from "../../common/ParrotLoader";
import WrongAnswer from "../Game/WrongAnswer";
import CorrectAnswer from "../Game/CorrectAnswer";
import Parrot from "../../common/Parrot";
import { Link } from "react-router-dom";
import ReactHtmlParser from 'react-html-parser';

type Phase = 'task' | 'answer' | 'already-solved' | 'no-data';

const TaskView: React.FC<RouteComponentProps<{}, {}, RouterTask>> = ({ history, location: { state } }) => {
  const gameContext = useContext(GameContext);
  const { taskId, title, description, sampleInput, sampleOutput } = state;
  const [taskData, setTaskData] = useState<{ inputs: string, completedAt: Date | null }>({
    inputs: '',
    completedAt: null
  });
  const [phase, setPhase] = useState<Phase>('no-data');
  const [loading, setLoading] = useState(false);
  const [answer, setAnswer] = useState<boolean>(false);
  const { dispatch, state: { login, isLoggedIn } } = useContext(GameContext);


  useEffect(() => {
    setLoading(true);
    setTimeout(async () => {
      const inputData = await getTaskData(login, taskId);
      const isAlreadySolved = !!inputData.completedAt;
      setTaskData(inputData);
      setPhase(isAlreadySolved ? 'already-solved' : 'task');
      setLoading(false);
    }, 1000);
  }, []);

  const TextField = ({ label, status = {}, ...props }: any) => {
    const [field, meta] = useField(props);
    return (
      <>
        <label htmlFor={props.id || props.name}>{label}</label>
        <input className={styles.answer} {...field} {...props} />
        {meta.touched && meta.error ? <div className={styles.error}>{meta.error}</div> : null}
      </>
    );
  };

  const validationSchema = Yup.object({
    answer: Yup.string().defined().required('Please give an answer')
  });

  const checkGivenAnswer = useCallback((taskId: string, answer: string) => {
    setLoading(true);
    setTimeout(async () => {
      const result = await submitTask(login, taskId, answer);
      setAnswer(result);
      setPhase('answer');
      setLoading(false);
    }, 1000);
  }, [answer, phase]);

  const submitForm = () => {
    return (
      <Formik validationSchema={validationSchema} initialValues={{ answer: '' }} onSubmit={async (values) => {
        checkGivenAnswer(taskId, values.answer);
      }}>
        {({ isSubmitting, status, errors }) => {
          return <Form className={theme.form}>
            <TextField name="answer" type="text" placeholder="your answer"
                       errors={status} />
            <button type="submit" className={styles.submit} disabled={isSubmitting}>
              Submit
            </button>
          </Form>
        }}
      </Formik>
    )
  };

  const renderData = (data: string) => {
    return <div>
      <H4>
        <pre className={styles.sample}>
          {data}
        </pre>
      </H4>
    </div>
  };

  const onContinue = () => {
    if (answer) {
      history.goBack();
    } else {
      setPhase('task');
    }
  };

  switch (phase) {
    case 'no-data':
      return <>{loading && <ParrotLoader label="Task loading..." />}</>;
    case 'task':
      return (
        <>
          {loading ? (
            <ParrotLoader label="Checking answer..." />
          ) : (
            <div className={styles.task}>
              <H2 className={styles.title}>{title}</H2>
              <div className={`${styles.description} task-description`}>
                {ReactHtmlParser(description)}
              </div>
              <div className={`${styles.yours}`}>
                <div style={{ width: "100%" }}>
                  <H4>Here is your <Link to={`task/${taskId}`} target="_blank">task input</Link>.</H4>
                </div>
              </div>
              <div className={styles.form}>
                {submitForm()}
              </div>
            </div>)
          }
        </>
      );
    case 'answer':
      return answer ? <CorrectAnswer onContinue={onContinue} /> : <WrongAnswer onContinue={onContinue} />;
    case 'already-solved':
      return <div className={styles.task}>
        <H2 style={{ color: "white" }}>You already solved this task.</H2>
        <Link to="/game">
          <Parrot mode="thumbsup" />
        </Link>
        <H4 className={styles.title}>Click the Parrot to go back.</H4>
      </div>;
    default:
      return <></>;
  }
};

export default TaskView;
import * as React from 'react';
import Parrot from '../common/Parrot';
import styles from './ParrotLoader.module.scss';

const ParrotLoader: React.FC<{ label: string }> = ({ label }) => {
  return (
    <div>
      <Parrot mode="regular" />
      <div className={styles.label}>{label}</div>
    </div>
  );
};

export default ParrotLoader;

import * as React from 'react';
import partyparrot from '../../assets/partyparrot.gif';
import sadparrot from '../../assets/sadparrot.gif';
import styles from './Parrot.module.scss';

interface Props {
  mode: 'sad' | 'regular' | 'triple';
}

const Parrot: React.FC<Props> = ({ mode }) => {
  const renderMode = () => {
    switch (mode) {
      case 'sad':
        return <img className={styles.parrot} src={sadparrot} alt="sad parrot" />;
      case 'triple':
        return (
          <>
            <img className={styles.parrot} src={partyparrot} alt="party parrot" />
            <img className={styles.parrot} src={partyparrot} alt="party parrot" />
            <img className={styles.parrot} src={partyparrot} alt="party parrot" />
          </>
        );
      case 'regular':
      default:
        return <img className={styles.parrot} src={partyparrot} alt="party parrot" />;
    }
  };

  return <div className={styles.container}>{renderMode()}</div>;
};

export default Parrot;

import React, {useContext, useEffect, useState} from 'react';
import {useParams} from 'react-router';
import {fetchAllTasks, getTaskData} from '../../Api/Api';
import {GameContext} from '../../Contexts/GameContext';
import theme from "../../theme.module.scss";
import styles from "./TaskInput.module.scss";
import {H3, H4} from '../../common/Typography';
import {Maybe} from 'true-myth';

const TaskInput: React.FC = () => {
  const {taskId} = useParams();
  const {dispatch, state: {login, isLoggedIn}} = useContext(GameContext);
  const [input, setInput] = useState('');
  const [taskTitle, setTaskTitle] = useState('');
  const [rows, setRows] = useState(0);
  const [cols, setCols] = useState(0);

  useEffect(() => {
    setInput('Loading ...');
    (async () => {
      const input = (await getTaskData(login, taskId)).inputs[0];
      const task = Maybe.of((await fetchAllTasks()).find(t => t.taskId === taskId));
      setInput(input);
      setTaskTitle(task.mapOrElse(() => '', t => t.title));

      const lines = input.split('\n');
      setRows(lines.length);
      setCols(lines[lines.length - 1].length);
    })();
  }, []);

  return (
    <div className={styles.task}>
      <H3 className={styles.title}>{taskTitle}</H3>
      <H4>Your input data</H4>
      <textarea className={styles.data} readOnly={true} value={input} rows={rows} cols={cols}/>
      <div className={theme.form} style={{flexDirection: 'row'}}>
        <div style={{width: '50%'}}>
          <button type={'button'} className={styles.copy} onClick={() => navigator.clipboard.writeText(input)}>
            Copy to clipboard
          </button>
        </div>
        <div style={{width: '50%'}}>
          <button type={'button'} className={styles.submit} onClick={() => window.close()}>Got it!</button>
        </div>
      </div>
    </div>
  );
};

export default TaskInput;

import React, { useContext, useEffect, useState } from "react";
import { authenticateGithub, GITHUB_CLIENT_ID, REDIRECT_URI } from "../Api/Api";
import styles from './GithubButton.module.scss';
import { GameContext } from "../Contexts/GameContext";

export const GithubLoginButton: React.FC<{ checkUser: (email: string) => void }> = ({ checkUser }) => {
  const [isLoggedIn, setLoggedIn] = useState(false);
  useEffect(() => {
    (async () => {
      const url = window.location.href;
      const hasCode = url.includes("?code=");

      if (hasCode) {
        const newUrl = url.split("?code=");
        window.history.pushState({}, "test", newUrl[0]);
        // setData({ ...data, isLoading: true });

        const requestData = {
          code: newUrl[1]
        };

        const auth = await authenticateGithub(requestData);
        auth.match({
          Err: e => {
          },
          Ok: data => {
            checkUser(data.login);
          }
        });
      }
    })();
  }, []);

  return (
    <div className="container">
      <div>
        <div className="login-container">
          {isLoggedIn ?
            <div>Logout</div>
            :
            <a className={styles.githubBtn}
               href={`https://github.com/login/oauth/authorize?scope=user:email&client_id=${GITHUB_CLIENT_ID}&redirect_uri=${REDIRECT_URI}`}>
              <span>SIGN IN WITH GITHUB</span>
            </a>
          }
        </div>
      </div>
    </div>
  );
};

export default GithubLoginButton;
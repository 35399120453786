import React from 'react';
import './App.css';
import { BrowserRouter } from 'react-router-dom';
import MainComponent from "./Views/Main";
import { GameContextProvider } from "./Contexts/GameContext";

const App: React.FC = () => {
  return (
    <BrowserRouter>
      <GameContextProvider>
        <MainComponent />
      </GameContextProvider>
    </BrowserRouter>
  );
};

export default App;
import * as React from 'react';
import { Route, RouteComponentProps, Switch } from "react-router";
import TaskRoute from "../TaskView/TaskRoute";
import Game from "./Game";

type Props = RouteComponentProps<void>;

const GameRoute: React.FC<Props> = ({ match }) => {
  return (
    <Switch>
      <Route exact path={`${match.path}`} component={Game} />
      <Route path={`${match.path}/task`} component={TaskRoute} />
    </Switch>
  );
};

export default GameRoute;

import * as React from 'react';
import styles from './Header.module.scss';
import {H1, H4, H5} from '../../../common/Typography';

const Header: React.FC = () => (
  <div className={styles.container}>
    <div className={styles.title}>
      <H1>Coding Adventures</H1>
      <H4>with Party Parrot</H4>
    </div>
  </div>
);

export default Header;

import * as React from 'react';
import { useContext, useEffect, useRef } from 'react';
import styles from './Main.module.scss';
import { SignIn } from "./SignIn/SignIn";
import { Route, RouteComponentProps, RouterProps, Switch } from 'react-router-dom';
import { Welcome } from "./Welcome/Welcome";
import Header from "./common/Header/Header";
import GameIntro from "./Game/GameIntro";
import RegistrationForm from "./Registration/RegistrationForm";
import { GameContext, GameContextProvider } from "../Contexts/GameContext";
import GameRoute from "./Game/GameRoute";
import PrivateRoute from "../Routing/PrivateRoute";
import ParrotLoader from "../common/ParrotLoader";
import { signin } from "../Api/Api";
import AgreementComponent from "../Agreement/Agreement";

const useLocalStorageApiKey = () => {
  const { dispatch, state: { login, isLoggedIn } } = useContext(GameContext);

  const loginRef = useRef(login);
  React.useEffect(() => {
    loginRef.current = login;
  }, [login, dispatch]);

  React.useEffect(() => {
    const login = localStorage.getItem('coding-adventures-player');
    if (!login) return dispatch({ type: "LOG_OUT" });

    dispatch({ type: "SET_LOGIN", login: login });
  }, [dispatch]);

  React.useEffect(() => {
    switch (isLoggedIn) {
      case true:
        return localStorage.setItem("coding-adventures-player", loginRef.current || "");
      case false:
        return localStorage.removeItem("coding-adventures-player");
    }
  }, [isLoggedIn]);
};

const useLoginOnApiKey = () => {
  const {
    dispatch,
    state: { login },
  } = useContext(GameContext);

  React.useEffect(() => {
    if (!login) return;

    const fetchPlayer = async() => {
      const user = await signin(login);
      user.match({
        Err: (e) => console.log("error"),
        Ok: player => dispatch({ type: "LOG_IN", nickname: player.nickname, taskAttempts: player.taskAttempts })
      });
    };
    fetchPlayer();
  }, [login, dispatch]);
};

const MainComponent: React.FC = () => {
  const { state: { login } } = useContext(GameContext);

  useLocalStorageApiKey();
  useLoginOnApiKey();

  if (login === null) {
    return <ParrotLoader label="Loading user data..." />
  }

  return (
    <div className={styles.container}>
      <div className={styles.background} />
      <div id="main" className={styles.content}>
        <Header />
          <Switch>
            <Route exact path="/" component={Welcome} />
            <Route exact path="/signin" component={SignIn} />
            <PrivateRoute exact path="/register" component={RegistrationForm} />
            <PrivateRoute exact path="/intro" component={GameIntro} />
            <PrivateRoute path="/game" component={GameRoute} />
            <Route path="/agreement" component={AgreementComponent} />
          </Switch>
      </div>
    </div>
  )
};

export default MainComponent;
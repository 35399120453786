import axios from 'axios';

import { Result } from 'true-myth';
import { ApiError, Player, Task, TaskAttempt, UserRegistrationDto } from "./types";

export const GITHUB_CLIENT_ID = "9436c5e9ce6e6cb49481";
export const REDIRECT_URI = "https://coding-adventures.sml.io/signin";
export const GOOGLE_CLIENT_ID = "1071668732006-4mr0l8p8t2fj22n6u4i78ttg02mp4ab8.apps.googleusercontent.com";

export const BASE_URL = process.env.REACT_API_URL || '/api/v1';

const fetchApiSuccess = <T>(data: T) => {
  console.log("fetchSuccess", data);
  return Result.ok(data);
};

const fetchErrors = <E>(error: ApiError | E) => {
  console.log("fetchErrors:" + error);
  return Result.err(error);
};

const get = async <T, Err = any>(url: string): Promise<T> => {
  const { data } = await axios.get(`${BASE_URL}${url}`);
  return data;
};

const post = async <T>(url: string, postData: any): Promise<Result<T, string>> => {
  try {
    const { data } = await axios.post(`${BASE_URL}${url}`, postData);
    return Result.ok(data);
  } catch (error) {
    return Result.err("Error: Try again.")
  }
};

const simplePost = async <T>(url: string, postData: any): Promise<T> => {
  const { data } = await axios.post(`${BASE_URL}${url}`, postData);
  return data;
};

export const signin = async (login: string) => {
  return (await post<Player>("/signin", { login })).match<Result<Player, string>>({
    Err: error => Result.err(error),
    Ok: user => Result.ok(user)
  })
};

export const registerPlayer = async (user: UserRegistrationDto): Promise<Result<Player, string>> => {
  return (await post<Player>('/register', user)).match<Result<Player, string>>({
    Err: error => Result.err(error),
    Ok: player => Result.ok(player)
  })
};

export const authenticateGithub = async ({ code }: { code: string }): Promise<Result<any, any>> => {
  return (await post("/authenticate-github", { code })).match({
    Err: error => Result.err("Login failed. Try again."),
    Ok: data => Result.ok(data)
  })
};

export const getTaskData = async (login: string, taskId: string): Promise<{ inputs: string, completedAt: Date }> =>
  await simplePost<{ inputs: string, completedAt: Date }>("/task-data", { login, taskId });

export const fetchAllTasks = async (): Promise<Task[]> => await get<Task[]>("/tasks");

export const submitTask = async (login: string, taskId: string, answer: string): Promise<boolean> =>
  await simplePost("/submit-task", { login, taskId, answer });

export const getUserTasks = async (login: string): Promise<TaskAttempt[]> =>
  simplePost("/user-tasks", { login });
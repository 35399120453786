import * as React from 'react';
import theme from '../../theme.module.scss';
import Info from '../common/Info';
import Parrot from '../common/Parrot';

interface Props {
  onContinue: () => void;
}

const WrongAnswer: React.FC<Props> = ({ onContinue }) => (
  <div>
    <Parrot mode="sad" />
    <Info mode="fail" label="Wrong!" />
    <button className={theme.button} onClick={onContinue}>
      Try again!
    </button>
  </div>
);

export default WrongAnswer;

import React, { useContext } from 'react';

import { Route, RouteProps } from 'react-router-dom';
import { GameContext } from "../Contexts/GameContext";
import { SignIn } from "../Views/SignIn/SignIn";

const PrivateRoute: React.FC<RouteProps> = ({ children, ...props }) => {
  const { state: { login } } = useContext(GameContext);
  if (!login)
    return <Route {...props} component={SignIn} />;
  return <Route {...props}>{children}</Route>;
};

export default PrivateRoute;
import cn from 'classnames';
import * as React from 'react';
import styles from './Typography.module.scss';

export const H1: React.FC<React.HTMLAttributes<HTMLHeadingElement>> = ({ children, className, ...props }) => (
  <h1 {...props} className={cn(styles.common, styles.h1, className)}>
    {children}
  </h1>
);
export const H2: React.FC<React.HTMLAttributes<HTMLHeadingElement>> = ({ children, className, ...props }) => (
  <h2 {...props} className={cn(styles.common, styles.h2, className)}>
    {children}
  </h2>
);
export const H3: React.FC<React.HTMLAttributes<HTMLHeadingElement>> = ({ children, className, ...props }) => (
  <h3 {...props} className={cn(styles.common, styles.h3, className)}>
    {children}
  </h3>
);
export const H4: React.FC<React.HTMLAttributes<HTMLHeadingElement>> = ({ children, className, ...props }) => (
  <h4 {...props} className={cn(styles.common, styles.h4, className)}>
    {children}
  </h4>
);
export const H5: React.FC<React.HTMLAttributes<HTMLHeadingElement>> = ({ children, className, ...props }) => (
  <h5 {...props} className={cn(styles.common, styles.h5, className)}>
    {children}
  </h5>
);
export const P: React.FC<React.HTMLAttributes<HTMLHeadingElement>> = ({ children, className, ...props }) => (
  <p {...props} className={cn(styles.common, styles.p, className)}>
    {children}
  </p>
);
export const Small: React.FC<React.HTMLAttributes<HTMLHeadingElement>> = ({ children, className, ...props }) => (
  <small {...props} className={cn(styles.common, styles.small, className)}>
    {children}
  </small>
);
export const Span: React.FC<React.HTMLAttributes<HTMLHeadingElement>> = ({ children, className, ...props }) => (
  <span {...props} className={cn(styles.common, styles.span, className)}>
    {children}
  </span>
);

import * as React from 'react';

const agreementFile = process.env.PUBLIC_URL + '/terms_and_conditions.pdf#zoom=120';

const AgreementComponent: React.FC = () => {
  return (
    <embed src={agreementFile} width="100%" height="1400px" type="application/pdf" />
  )
};

export default AgreementComponent;
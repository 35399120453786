import * as React from 'react';
import {Link} from 'react-router-dom';
import Parrot from '../../common/Parrot';
import {H2, P} from '../../common/Typography';
import styles from './Welcome.module.scss';

export const Welcome: React.FC = () => {
  return (
    <>
      <Parrot mode="regular" />
      <P style={{paddingTop: '1em'}}>
        Join the Parrot adventures<br/><br/>and win a 50% discount for a <a style={{color: '#F06BA8'}} href="https://www.scalar-conf.com/" target="_blank">Scalar</a> ticket.
      </P>
      <Link to="/signin" style={{textDecoration: 'none'}}>
        <H2 className={styles.beginButton}>Click here to start!</H2>
      </Link>
    </>
  );
};

import React from 'react';
import immer from 'immer';
import { TaskAttempt } from '../Api/types';

export interface GameState {
  login: string;
  nickname: string;
  taskAttempts: TaskAttempt[];
  isLoggedIn: boolean;
}

export type GameAction =
  | { type: "SET_LOGIN", login: string }
  | { type: "UPDATE_PLAYER_TASKS"; taskAttempts: TaskAttempt[] }
  | { type: "LOG_IN", nickname: string; taskAttempts: TaskAttempt[] }
  | { type: "LOG_OUT" }

export const initialGameState: GameState = {
  login: '',
  nickname: '',
  taskAttempts: [],
  isLoggedIn: false,
};

const GameReducer = (state: GameState, action: GameAction): GameState => {
  console.log("reducer: ", action);
  switch (action.type) {
    case "SET_LOGIN":
      return immer(state, (draftState) => {
        console.info("setting login", action);
        draftState.login = action.login;
      });
    case "LOG_IN":
      return immer(state, (draftState) => {
        console.log("logging in", action);
        draftState.nickname = action.nickname;
        draftState.taskAttempts = action.taskAttempts;
        draftState.isLoggedIn = true;
      });
    case "UPDATE_PLAYER_TASKS":
      return immer(state, (draftState) => {
        draftState.taskAttempts = action.taskAttempts;
      });
    case "LOG_OUT":
      return immer(state, (draftState) => {
        draftState.login = '';
        draftState.nickname = '';
        draftState.isLoggedIn = false;
        draftState.taskAttempts = [];
      });
    default:
      return state;
  }
};

export const GameContext = React.createContext<{
  state: GameState;
  dispatch: React.Dispatch<GameAction>;
}>({
  state: initialGameState,
  dispatch: (...args: any[]): any => {
  }
});


export const GameContextProvider: React.FC = ({ children }) => {
  const [state, dispatch] = React.useReducer(GameReducer, initialGameState);

  return <GameContext.Provider value={{ state, dispatch }}>{children}</GameContext.Provider>;
};
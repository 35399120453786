import * as React from 'react';
import GithubLoginButton from "../../Github/GithubLoginButton";
import styles from './GithubLogin.module.scss';

const GithubLogin: React.FC<{ checkUser: (login: string) => void }> = ({ checkUser }) => {
  return (
    <div className={styles.githubBtn}>
      <GithubLoginButton checkUser={checkUser} />
    </div>
  )
};

export default GithubLogin;
import React, { useCallback, useContext, useState } from "react";
import { H3 } from "../../common/Typography";
import styles from "./RegistrationForm.module.scss";
import theme from '../../theme.module.scss';
import { Form, Formik, useField } from "formik";
import { RouterProps } from "react-router";
import ParrotLoader from "../../common/ParrotLoader";
import * as Yup from 'yup';
import { registerPlayer } from "../../Api/Api";
import { GameContext } from "../../Contexts/GameContext";
import { Player } from "../../Api/types";

type Props = {
  email: string;
  name: string;
} & RouterProps;

type RegisterData = {
  nickname: string;
  agreement: boolean;
}

const initialValues: RegisterData = {
  nickname: '',
  agreement: false,
};

const validationSchema = Yup.object({
  nickname: Yup.string()
    .min(4, 'Nickname must be at least 4 characters long')
    .max(15, 'Nickname can be max 15 characters')
    .required('Nickname field is required'),
  agreement: Yup.boolean()
    .required('Required')
    .oneOf([true], 'You must accept the terms & privacy policy.'),
});

const RegistrationForm: React.FC<Props> = (props) => {
  const [isLoading, setLoading] = useState(false);
  const [isRedirecting, setRedirecting] = useState(false);
  const { dispatch, state: { login, isLoggedIn } } = useContext(GameContext);

  console.log("registration: ", login, isLoggedIn);

  const CheckboxField = ({ children, ...props }: any) => {
    const [field, meta] = useField({ ...props, type: 'checkbox' });

    return (
      <>
        <label className={`${styles.container} ${styles.agreement}`}>
          <input {...field} {...props} type="checkbox" />
          {children}
          <span className={styles.checkmark}></span>
        </label>
        {meta.touched && meta.error ? <div className={styles.error}>{meta.error}</div> : null}
      </>
    );
  };

  const TextField = ({ label, status = {}, ...props }: any) => {
    const [field, meta] = useField(props);
    return (
      <>
        <label htmlFor={props.id || props.name}>{label}</label>
        <input className="text-input" {...field} {...props} />
        {meta.touched && meta.error ? <div className={styles.error}>{meta.error}</div> : null}
      </>
    );
  };

  const onSubmit = useCallback(
    async ({ nickname, agreement }, actions) => {
      const data = await registerPlayer({ nickname, login: login, agreement });
      data.match({
        Err: (error: any) => {
          console.log("errors: ", error);
        },
        Ok: (player: Player) => {
          dispatch({
            type: 'LOG_IN',
            nickname: player.nickname,
            taskAttempts: player.taskAttempts
          });
          props.history.push("/intro");
        }
      });
      actions.setSubmitting(false);
    },
    [props.history]
  );

  const renderForm = React.useCallback(
    () => (
      <Form className={theme.form}>
        <TextField className={theme.field} name="nickname" type="text" placeholder="your nickname" />
        <CheckboxField name="agreement">
          I accept the&nbsp;
          <a href="/agreement" target="_blank">
            terms &amp; privacy policy
          </a>
        </CheckboxField>
        <button type="submit" className={theme.button}>
          Join the game
        </button>
      </Form>
    ), []);

  return (
    <div className={styles.container}>
      {isRedirecting ? (
        <ParrotLoader label="Registering..." />
      ) : (
        <div style={{ paddingTop: 200 }}>
          <H3 style={{ paddingBottom: 50, color: "white" }}>Register your nickname</H3>
          <Formik validationSchema={validationSchema} initialValues={initialValues} onSubmit={onSubmit}>
            {({ isSubmitting, status, errors }) => {
              return <Form className={theme.form}>
                <TextField className={theme.field} name="nickname" type="text" placeholder="your nickname"
                           errors={status} />
                <CheckboxField name="agreement" errors={status}>
                  I accept the&nbsp;
                  <a className={styles.terms} href="/agreement" target="_blank">
                    terms &amp; privacy policy
                  </a>
                </CheckboxField>
                <button type="submit" className={theme.button} disabled={isSubmitting}>
                  Join the game
                </button>
              </Form>
            }}
          </Formik>
        </div>
      )}
    </div>
  )
};

export default RegistrationForm;
import * as React from 'react';
import { ReactNode, useContext, useEffect, useState } from 'react';
import { Task } from "../../Api/types";
import { Maybe } from "true-myth";
import styles from "./GameOverview.module.scss";
import GameTile from "./GameTile";
import { Link } from "react-router-dom";
import { fetchAllTasks, getUserTasks} from "../../Api/Api";
import { GameContext } from "../../Contexts/GameContext";
import ParrotLoader from '../../common/ParrotLoader';

type Phase = 'no-data' | 'game';

const Game: React.FC = () => {
  const gameContext = useContext(GameContext);
  const [parrotPosition, setParrotPosition] = useState(1);
  const [tasks, setTasks] = useState<Task[]>([]);
  const [firstUnsolvedTask, setFirstUnsolvedTask] = useState<Maybe<Task>>(Maybe.nothing<Task>());
  const [solvedTasks, setSolvedTasks] = useState<Set<string>>(new Set);
  const [allTasksSolved, setAllTasksSolved] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const {dispatch, state: {login, isLoggedIn}} = useContext(GameContext);

  useEffect(() => {
    (async () => {
      const tasks = await fetchAllTasks();
      setTasks(tasks);
      const userTasks = await getUserTasks(login);

      setSolvedTasks(new Set(userTasks.filter(task => task.completedAt !== null).map(task => task.taskId)));
      const unsolvedUserTasksIds = userTasks.filter(task => task.completedAt === null).map(task => task.taskId);
      if (userTasks.length === 0) { // no attempts yet
        setFirstUnsolvedTask(Maybe.just(tasks[0]));
      } else if (userTasks.length === tasks.length && unsolvedUserTasksIds.length === 0) { // all tasks completed
        setParrotPosition(tasks.length);
        setAllTasksSolved(true);
      } else {
        console.log('parrot position: ', parrotPosition);
        const solvedTasks = userTasks.filter(task => task.completedAt).map(task => task.taskId);
        const firstUnsolvedTask = Maybe.of(tasks.find(task => !solvedTasks.includes(task.taskId)));
        setFirstUnsolvedTask(firstUnsolvedTask);
        console.log('first unsolved task: ', firstUnsolvedTask);
        firstUnsolvedTask.mapOr(setParrotPosition(1), (task) => setParrotPosition(task.order));
      }
      setLoaded(true);
    })();
  }, []);

  const drawTiles = (tasks: Task[], solvedTasks: Set<String>, firstUnsolvedTask: Maybe<Task>) => {
    console.info('parrot: ', parrotPosition);
    return tasks.map(task => {
      const isCurrentTaskAvailable = firstUnsolvedTask.mapOrElse(() => true, t => task.order <= t.order);

      return isCurrentTaskAvailable ?
        <div key={task.taskId} className={styles.cell}>
          <Link to={{
            pathname: '/game/task',
            state: {
              taskId: task.taskId,
              title: task.title,
              description: task.description,
              sampleInput: task.sampleInput,
              sampleOutput: task.sampleOutput,
            }
          }}>
            <GameTile image={task.image} attachParrot={!allTasksSolved && parrotPosition === task.order} isAvailable={true} isSolved={solvedTasks.has(task.taskId)}/>
          </Link>
        </div>
        :
        <div key={task.taskId} className={styles.cell}>
          <GameTile image="partyparrot-dim.png" message="Solve the current task first"/>
        </div>;
      }
    );
  };

  const discount = (allSolved: boolean) => {
    return allSolved ?
      (
        <div className={styles.congratulations}>
          Congratulations! Here's your 50% discount code for a Scalar ticket:
          <div className={styles.discount}>ParrotXScalar50</div>
          Please buy your ticket at the <a href="https://app.evenea.pl/event/scalar2023/?lang=en" target="_blank">event website</a>.
          <div className={styles.hurry}>Hurry up! The number of available codes is limited!</div>
        </div>
      ) :
      null
  }

  if (!loaded)
    return <ParrotLoader label="Game loading..."/>
  else
    return (
      <div className={styles.overview}>
        {discount(allTasksSolved)}
        <div className={styles.grid}>
          {drawTiles(tasks, solvedTasks, firstUnsolvedTask)}
        </div>
        <div className={styles.credits}>Graphics by <a href="https://midjourney.com/" target="_blank">Midjourney</a>
        </div>
      </div>
    )
};

export default Game;
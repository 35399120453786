import cn from 'classnames';
import * as React from 'react';
import styles from './Info.module.scss';

interface Props {
  mode?: 'default' | 'success' | 'fail';
  label: string;
}

const Info: React.FC<Props> = ({ mode = 'default', label }) => {
  return <div className={cn(styles.container, styles[mode])}>{label}</div>;
};

export default Info;

import React, { useContext, useState } from 'react';
import styles from './Login.module.scss';
import GithubLogin from "./GithubLogin";
import GoogleBtn from "./GoogleBtn";
import { Redirect, RouterProps } from "react-router";
import { signin } from "../../Api/Api";
import ParrotLoader from "../../common/ParrotLoader";
import { GameContext } from "../../Contexts/GameContext";

export const SignIn: React.FC<RouterProps> = (props) => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const { dispatch, state: { isLoggedIn } } = useContext(GameContext);


  const checkUser = async (login: string) => {
    const playerData = await signin(login);
    playerData.match({
      Err: () => {
        console.log("login: ", login);
        dispatch({ type: "SET_LOGIN", login });
        props.history.push('/register')
      },
      Ok: (player) => {
        dispatch({ type: "SET_LOGIN", login: login });
        props.history.push('/intro');
      }
    });
  };

  return (
    <>
      {isLoading ?
        <ParrotLoader label="Checking user..." /> :
        <div className={styles.container}>
          <GoogleBtn checkUser={checkUser} />
          <GithubLogin checkUser={checkUser} />
        </div>
      }
    </>
  )
};
import React from 'react';
import { Route, RouteComponentProps, Switch } from "react-router";
import TaskView from "./TaskView";
import TaskInput from './TaskInput';

type Props = RouteComponentProps<void>;
const TaskRoute: React.FC<Props> = ({ match }) => {

  return (
    <Switch>
      <Route exact path={`${match.path}`} component={TaskView}/>
      <Route path={`${match.path}/:taskId`} component={TaskInput}/>
    </Switch>
  )
};

export default TaskRoute;
import * as React from 'react';
import { H2, H3 } from "../../common/Typography";
import styles from './GameIntro.module.scss';
import { Link } from "react-router-dom";

const GameIntro: React.FC = () => {
  return (
    <div className={styles.container}>
      <div className={styles.storyContainer}>
        <H3 style={{ fontSize: "1.5em", lineHeight: "2" }}>
          Looking forward to Scalar conference?<br/><br/>
          Join coding adventures with Party Parrot, solve the tasks and win a 50% discount for a ticket!<br/><br/>
          We can't wait to meet you and other Scala enthusiasts in Warsaw!
        </H3>
        <Link to="/game">
          <H2 className={styles.beginButton}>Start the Journey!</H2>
        </Link>
      </div>
    </div>
  )
};

export default GameIntro;